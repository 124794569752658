.bg-pri {
  background-color: @brand-primary;
}
p{
  font-size: 16px !important;
}
.banner{
  background-image: url('/images/banner.jpg');
  color:#fff;
  background-position: center;
}
.border-top{
    background-color: #fff;
    border-bottom: 3px solid @brand-primary;
}
.icone-menu-esquerda{
  background-color: @brand-primary !important;
}
.menu-esquerda > ul > li:hover i{
  background-color: #12509E !important;
}
.menu-esquerda > ul > li:hover div{
  font-weight: bold;
  color: #12509E !important;

}
.icone-recurso{
  color: @brand-primary !important;
}

input, select, textarea{
  background-color: #EBEBED!important;
  border-radius: 0!important;
  border: 0!important;
}
textarea{
  width: 100%;
  min-height: 150px;
  border: 0;
}
label {
  margin-bottom: 0!important;
  font-size: 12px!important;
  color: #444444;
  font-weight: normal;

}
.input-group-addon {

  border: 0!important;

}
.form-control {
  height: 30px!important;
  border: 0;
}
.form-group {
  margin-bottom: 3px;
}

//////////////////

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: inherit;
}

.navbar-collapse {
   padding-right: 0;
   padding-left: 0;
}
