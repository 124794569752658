.thumb {
  width: 120px;
  float: none;
  position: relative;
  /*top: 50%;
  transform: translateY(-50%);*/
  border: solid 1px @thumb-border;
}

.container-thumb{
  height: 105px;
}

.box-thumb{
  width: 142px;
  height: 105px;
  border: solid 1px @thumb-border;
  font-size: 16px;
  font-weight: bold;
  color: #767c7e;
  font-family: Verdana, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 28px 5px 0 5px;
}

.box-thumb-hover{
  background-color: #e7fff8;
}

form .progress {
  line-height: 15px;
}


.progress {
  margin: 3px;
  display: inline-block;
  width: 300px;
  border: 1px groove @progress-border;
  height: 25px;
}

.progress div {
  padding-top: 3px;
  font-size: 14px;
  background: @progress-background;
  color: @progress-color;
  height: 23px;
  width: 0;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}