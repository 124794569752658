.navbar-brand{
   height: inherit !important;
   padding: 0 15px;
}
.panel-default > .panel-heading{
  background-color: @brand-primary !important;
}
.navbar-nav > li > a {
  line-height: 30px;
}
.navbar-nav > li:hover {
  background-color: #1F5CA0 !important;
  color: #FFFFFF !important;
}
.nav > li > a > i {
  font-size: 20px !important;
}
.btn-top{
  padding-bottom: 10px !important;
  padding-top: 15px !important;
}
.btn-top-icon {
  padding-bottom: 18px !important;
  padding-top: 22px !important;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #FFFFFF !important;
  background-color: #1A4B82 !important;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: #FFFFFF !important;
  background-color: #1A4B82 !important;
}
