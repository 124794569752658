.thumb {
  width: 120px;
  float: none;
  position: relative;
  /*top: 50%;
  transform: translateY(-50%);*/
  border: solid 1px #e5e5e5;
}
.container-thumb {
  height: 105px;
}
.box-thumb {
  width: 142px;
  height: 105px;
  border: solid 1px #e5e5e5;
  font-size: 16px;
  font-weight: bold;
  color: #767c7e;
  font-family: Verdana, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 28px 5px 0 5px;
}
.box-thumb-hover {
  background-color: #e7fff8;
}
form .progress {
  line-height: 15px;
}
.progress {
  margin: 3px;
  display: inline-block;
  width: 300px;
  border: 1px groove #f0f0f0;
  height: 25px;
}
.progress div {
  padding-top: 3px;
  font-size: 14px;
  background: #5cb85c;
  color: #fff;
  height: 23px;
  width: 0;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.navbar-brand {
  height: inherit !important;
  padding: 0 15px;
}
.panel-default > .panel-heading {
  background-color: #2062A8 !important;
}
.navbar-nav > li > a {
  line-height: 30px;
}
.navbar-nav > li:hover {
  background-color: #1F5CA0 !important;
  color: #FFFFFF !important;
}
.nav > li > a > i {
  font-size: 20px !important;
}
.btn-top {
  padding-bottom: 10px !important;
  padding-top: 15px !important;
}
.btn-top-icon {
  padding-bottom: 18px !important;
  padding-top: 22px !important;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #FFFFFF !important;
  background-color: #1A4B82 !important;
}
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #FFFFFF !important;
  background-color: #1A4B82 !important;
}
.bg-pri {
  background-color: #2062A8;
}
p {
  font-size: 16px !important;
}
.banner {
  background-image: url('/images/banner.jpg');
  color: #fff;
  background-position: center;
}
.border-top {
  background-color: #fff;
  border-bottom: 3px solid #2062A8;
}
.icone-menu-esquerda {
  background-color: #2062A8 !important;
}
.menu-esquerda > ul > li:hover i {
  background-color: #12509E !important;
}
.menu-esquerda > ul > li:hover div {
  font-weight: bold;
  color: #12509E !important;
}
.icone-recurso {
  color: #2062A8 !important;
}
input,
select,
textarea {
  background-color: #EBEBED!important;
  border-radius: 0!important;
  border: 0!important;
}
textarea {
  width: 100%;
  min-height: 150px;
  border: 0;
}
label {
  margin-bottom: 0!important;
  font-size: 12px!important;
  color: #444444;
  font-weight: normal;
}
.input-group-addon {
  border: 0!important;
}
.form-control {
  height: 30px!important;
  border: 0;
}
.form-group {
  margin-bottom: 3px;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: inherit;
}
.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}
